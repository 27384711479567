<template>
<div>
  <div class="head">
    <h4 class="head-label">我的流量套餐</h4>
    <div class="head-item">
      <div class="item-title">流量余额（台）</div>
      <div class="item-amount">{{ formData.num1 }}</div>
    </div>
    <div class="head-item">
      <div class="item-title">已绑定设备（台）</div>
      <div class="item-amount">{{ formData.num2 }}</div>
    </div>
    <div class="head-item">
      <div class="item-title">剩余可绑定设备（台）</div>
      <div class="flex">
        <span class="item-amount">{{ formData.num3 }}</span>
<!--        <a style="margin-left: 12px;" href="javascript:;" @click="get_package_money">&nbsp;去充值</a>-->
      </div>
    </div>
  </div>
  <div class="table-list">
    <Table :data="data" :columns="columns" :loading="loading"></Table>
  </div>
  <div class="ser-page">
    <Page style="margin-top: 10px;text-align: right;" v-model="search.page" :total="search.total"
          :page-size="search.pageSize" :page-size-opts="[10,15,20]" show-total show-sizer transfer
          @on-change="page_change" @on-page-size-change="pageSize_change"></Page>
  </div>
  <Modal
      v-model="modal"
      :mask-closable="false"
      :footer-hide="true"
      title="充值流量套餐">
    <div class="pay-type">充值方式</div>
    <div class="pay-item">
      <i class="iconfont icon-weixinzhifu" style="color: #00c250"></i>
      <span style="margin-left: 6px;">微信支付</span>
      <i class="iconfont icon-xuanze" style="position: absolute;right: -1px;top: -4px;color: #ed4014;"></i>
    </div>
    <div class="pay-label">充值数量</div>
    <div class="pay-data">
      <InputNumber :min="1"
                   v-model="amount"
                   :active-change="false"
                   :formatter="value => `${value} 台`"
                   :parser="value => value.replace('台', '')"
                   style="width: 120px;">
      </InputNumber>
      <span style="margin-left: 12px;">需支付：<span style="color: #ed4014;">￥ {{ price }}</span></span>
      <div class="tips">设备流量套餐：{{ money }}元/台</div>
    </div>

    <div style="margin-top: 24px;">
      <Button size="large" style="width: 100px;" type="success" @click="Recharge">充值</Button>
    </div>
  </Modal>
  <!-- 微信支付二维码 -->
  <Modal :mask-closable="false" v-model="CodeModal" @on-cancel="cancel_pay()" class-name="modal" :transfer="false"
         :footer-hide="true" width="250">
    <div style="text-align: center;width: 100%;font-weight: bold;">
      微信支付
    </div>
    <vue-qr :text="code_url" :size="220"></vue-qr>
  </Modal>
</div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  name: "package",
  components: {
    vueQr
  },
  data(){
    return{
      modal:false,
      CodeModal:false,
      money:0,
      amount:1,
      formData:{
        num1:0,
        num2:0,
        num3:0
      },
      search:{
        page:1,
        total:0,
        pageSize:15
      },
      loading:false,
      columns: [
        {title: '订单号', key: 'orderid'},
        {title: '订单数量', key: 'amount'},
        {title: '订单金额', key: 'pay_money'},
        {title: '订单状态', key: 'status',render:(h,params)=>{
          return h('span',this.statusArr[params.row.status])
          }},
        {title: '订单时间', key: 'create_date'},
      ],
      data:[],
      statusArr:['待支付','支付成功','支付失败'],
      timeout:null,
      code_url:''
    }
  },
  created() {
    this.init()
  },
  computed:{
    price(){
      return this.amount * this.money
    }
  },
  methods:{
    init(){
      this.get_package()
      this.get_order_list()
    },
    get_package(){
      var that = this
      this.requestApi('/adm/get_package_info').then((res)=>{
        if(res.status){
          that.formData = res.data
        }
      })
    },
    get_order_list(){
      var that = this
      this.loading = true
      this.requestApi('/adm/get_package_order',{search:this.search}).then((res)=>{
        if(res.status){
          that.search.total = res.data.total
          that.data = res.data.data
        }
        that.$nextTick(function () {
          that.loading = false
        })
      })
    },
    //获取设备流量套餐费用
    get_package_money(){
      var that = this
      this.requestApi('/adm/get_package_money').then((res)=>{
        if (res.status){
          that.modal = true
          that.money = res.data
        }
      })
    },
    //充值流量套餐
    Recharge(){
      var that = this
      this.$Modal.confirm({
        title:'提示',
        content:'确定要充值吗？',
        onOk(){
          that.requestApi('/adm/create_package_order',{data:that.amount}).then((res)=>{
            if (res.code==200){
              that.modal = false
              that.code_url = res.data.code_url
              that.CodeModal = true
              that.timeout = setInterval(function() {
                that.requestApi('/adm/get_package_order_status', {
                  order_number: res.data.order_number
                }).then(
                    (res) => {
                      if (res.data == 1) {
                        clearInterval(that.timeout)
                        that.alertSucc('支付成功')
                        that.CodeModal = false
                        that.init()
                      }
                    }
                )
              }, 500)
            }else {
              that.alertErr(res.msg)
            }
          })
        }
      })
    },
    //点击取消
    cancel_pay() {
      clearInterval(this.timeout)
    },
    //切换页码
    page_change(page) {
      this.search.page = page
      this.get_order_list()
    },
    //切换每页条数
    pageSize_change(pageSize) {
      this.search.page = 1
      this.search.pageSize = pageSize
      this.get_order_list()
    },
  }
}
</script>

<style scoped>
  .head{
    padding: 24px;
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-bottom: 12px;
  }
  .head-label{
    margin-bottom: 12px;
  }
  .head-item{
    display: inline-block;
    width: 240px;
    padding-left: 24px;
  }
  .item-title{
    color: #999999;
    font-size: 13px;
  }
  .item-amount{
    color: #ed4014;
    font-size: 18px;
    font-weight: bold;
  }
  .pay-type{
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .pay-item{
    display: inline-flex;
    align-items: center;
    padding: 7px 20px;
    border: 1px solid #ed4014;
    border-radius: 3px;
    position: relative;
  }
  .pay-label{
    margin: 12px 0;
    font-size: 14px;
    font-weight: bold;
  }
  .tips{
    margin-top: 8px;
  }
</style>
